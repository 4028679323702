import ClevryImage from "assets/clevry.webp";
import GoogleTrustServicesImage from "assets/google-trust-services.webp";
import LukeImage from "assets/luke-griffiths.webp";
import { Block } from "components/block/block";
import { Content } from "components/content/content";
import { IconButton } from "components/icons/icon-button";
import { EmailIcon, GithubIcon, LinkedinIcon } from "components/icons/icons";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { state } from "store/store";

import FutropolisImage from "assets/futropolis.webp";
import PropagateImage from "assets/propagate.webp";
import VideoDistortImage from "assets/video-distort.webp";
import RyskImage from "assets/rysk.webp";
import HakiImage from "assets/haki.webp";

import { useIsMobile } from "helpers/useIsMobile";
import { ThemeContext, TweakContext } from "App";

const colors = ["#2ae9bc", "#99ff00"];

export const Pages: React.FC = () => {
  const isMobile = useIsMobile();
  const { isAccesibilityMode } = useContext(ThemeContext);
  const isSimpleLayout = isMobile || isAccesibilityMode;

  const scrollArea = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = document.querySelector(
      ".scrollArea"
    ) as HTMLDivElement | null;
    scrollArea.current = element;
  }, []);

  const handleImageScroll = useCallback(
    (
      e: React.WheelEvent<
        HTMLAnchorElement | HTMLDivElement | HTMLButtonElement
      >
    ) => {
      if (scrollArea.current) {
        if (e.type === "wheel") {
          const event = e as React.WheelEvent<HTMLAnchorElement>;
          scrollArea.current.scrollTop += event.deltaY / 2;
        }
      }
    },
    [scrollArea]
  );

  return (
    <>
      <Block factor={1} offset={isSimpleLayout ? 0.9 : 1}>
        <Content side="left" className="portfolio-block" color={colors[0]}>
          <h2 className="bg-white">Hari Chauhan</h2>
          <h4>
            A London-based <span className="accent">creator</span> with a focus
            on <span className="accent">communication</span>,{" "}
            <span className="accent">local community</span>, and{" "}
            <span className="accent">unique representations</span> of the
            everyday.
          </h4>
          <h4 style={{ alignSelf: "flex-end" }} className="text-right ">
            With a background in{" "}
            <span className="accent">software engineering</span>, they have
            worked within a diverse range of fields including{" "}
            <span className="accent">3D graphics, AI and Web3.</span>
          </h4>
          <h4 style={{ alignSelf: "flex-end" }}>
            Currently freelancing, they are always{" "}
            <span className="accent">seeking new opportunities</span> to apply
            existing knowledge and gain new skills.
          </h4>
          <h4 style={{ color: "var(--blue-600)" }}>
            Projects of note are included below.
          </h4>
          <div className="bottom-bar">
            <div className="socials">
              <IconButton
                icon={GithubIcon}
                href="https://github.com/haric95"
                className="icon"
              />
              <IconButton
                icon={LinkedinIcon}
                href="https://www.linkedin.com/in/hari-chauhan-a07213122/"
                className="icon"
              />
              <IconButton
                icon={EmailIcon}
                href="mailto:hari-c@hotmail.co.uk"
                className="icon"
                onWheel={(e) => handleImageScroll(e)}
              />
            </div>
          </div>
        </Content>
      </Block>
      <Block factor={isSimpleLayout ? 1 : -1} offset={2}>
        <Content side="right" className="portfolio-block" color={colors[1]}>
          <h2>Rysk Finance</h2>
          <h4 style={{ color: "var(--blue-600)" }}>
            An <span className="accent-2">ethereum</span>-based decentralised
            finance application.
          </h4>
          <h4 style={{ color: "var(--blue-600)" }} className="text-right">
            Hari was contracted to lead{" "}
            <span className="accent-2">front-end development</span> on the
            application dashboard as well as the marketing site.
          </h4>
          <h4 style={{ color: "var(--blue-600)" }}>
            Technologies of note:{" "}
            <span className="accent-2">React, Ethers, Tailwind, GLSL</span>
          </h4>
          <a
            className="image-container unstyled-button site-link"
            href="https://rysk.finance"
            target="_blank"
            rel="noreferrer"
            onWheel={(e) => handleImageScroll(e)}
          >
            <img src={RyskImage} alt="Rysk" />
          </a>
        </Content>
      </Block>
      <Block factor={1} offset={3}>
        <Content side="left" color={colors[0]} className="portfolio-block">
          <h3>Web Development</h3>
          <div className="others">
            <div className="other">
              <a
                className="image-container unstyled-button site-link other-site-link"
                href="https://futropolis.ft.com/"
                target="_blank"
                rel="noreferrer"
                onWheel={(e) => handleImageScroll(e)}
              >
                <img src={FutropolisImage} alt="Futropolis" />
              </a>
              <h4 style={{ color: "var(--blue-600)" }}>
                Front-end engineering for the Financial Times project in
                partnership with Honeywell. Making use of{" "}
                <span className="accent">Three.JS</span> for web 3D graphics.
              </h4>
            </div>
            <div className="other">
              <a
                className="image-container unstyled-button site-link other-site-link"
                href="https://lukegriffiths.co.uk"
                target="_blank"
                rel="noreferrer"
                onWheel={(e) => handleImageScroll(e)}
              >
                <img src={LukeImage} alt="Luke Griffiths" />
              </a>
              <h4 style={{ color: "var(--blue-600)" }}>
                A <span className="accent">portfolio site</span> for
                London-based Graphic Designer{" "}
                <span className="accent">Luke Griffiths</span>
              </h4>
            </div>
            <div className="other">
              <a
                className="image-container unstyled-button site-link other-site-link"
                href="https://pki.goog"
                target="_blank"
                rel="noreferrer"
                onWheel={(e) => handleImageScroll(e)}
              >
                <img src={GoogleTrustServicesImage} alt="Clevry" />
              </a>
              <h4 style={{ color: "var(--blue-600)" }}>
                <span className="accent">Google’s PKI site</span>. Built whilst
                working for Phantom digital agency
              </h4>
            </div>
          </div>
        </Content>
      </Block>
      <Block factor={isSimpleLayout ? 1 : -1} offset={4}>
        <Content side="right" className="portfolio-block" color={colors[1]}>
          <h2>Propagate Festival</h2>
          <h4 style={{ color: "var(--blue-600)" }}>
            A small festival organised by the{" "}
            <span className="accent-2">GATE executants</span>. A diverse
            community of creators based in East London.
          </h4>
          <h4 style={{ color: "var(--blue-600)" }} className="text-right">
            Hari&apos;s contribution consisted of,{" "}
            <span className="accent-2">audio-reactive visuals</span> built in{" "}
            <span className="accent-2">MaxMSP</span>, design and development of
            the festival&apos;s <span className="accent-2">website</span> as
            well as <span className="accent-2">cooking</span> for the 90+
            attendees.
          </h4>
          <a
            className="image-container unstyled-button site-link"
            href="https://thegategroup.info"
            target="_blank"
            rel="noreferrer"
            onWheel={(e) => handleImageScroll(e)}
          >
            <img src={PropagateImage} alt="Propagate" />
          </a>
        </Content>
      </Block>
      <Block factor={1} offset={5}>
        <Content side="left" color={colors[0]} className="portfolio-block">
          <h3>HAKI</h3>
          <h4 style={{ color: "var(--blue-600)" }}>
            A project aimed at bringing the{" "}
            <span className="accent">community</span> in Fish Island together
            through a pay-what-you-can biweekly{" "}
            <span className="accent">supper club</span>, feeding 30+ people
            during each event.
          </h4>
          <h4 style={{ color: "var(--blue-600)" }} className="text-right">
            HAKI provides Hari with the opportunity to engage in{" "}
            <span className="accent">community organising</span> as well as{" "}
            <span className="accent">culinary experimentation.</span>
          </h4>
          <h4>
            By <span className="accent">Hari and Kiara</span>
          </h4>
          <div
            className="image-container"
            onWheel={(e) => handleImageScroll(e)}
          >
            <img src={HakiImage} alt="Propagate" />
          </div>
        </Content>
      </Block>
    </>
  );
};
