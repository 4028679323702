import { ThemeContext } from "App";
import { useGLTF } from "drei";
import { useIsMobile } from "helpers/useIsMobile";
import lerp from "lerp";
import React, { useContext, useMemo, useRef } from "react";
import { useFrame, useThree } from "react-three-fiber";
import { state } from "store/store";
import { Mesh, MeshBasicMaterial } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { Sphere } from "drei";

type GLTFSectionsResult = GLTF & {
  nodes: {
    plane1: THREE.Mesh;
    plane2: THREE.Mesh;
    plane3: THREE.Mesh;
    plane4: THREE.Mesh;
    propagate: THREE.Mesh;
    distort: THREE.Mesh;
    sphere: THREE.Mesh;
    rysk: THREE.Mesh;
  };
};

type GLTFGeosResult = GLTF & {
  nodes: {
    geo1: THREE.Mesh;
    geo2: THREE.Mesh;
    geo3: THREE.Mesh;
  };
};

type GLTFPalestineResult = GLTF & {
  nodes: {
    Text: THREE.Mesh;
  };
};

export const useBackgroundItem = (
  appearAtSection: number,
  disappearAtSection: number,
  maxSize: number
) => {
  const { pages, sections } = state;
  const { size, viewport } = useThree();
  const viewportWidth = viewport.width;
  const viewportHeight = viewport.height;
  const canvasWidth = viewportWidth;
  const canvasHeight = viewportHeight;
  const isMobile = useIsMobile();
  const margin = isMobile ? 0.1 : 0.8;
  const contentMaxWidth = canvasWidth * (isMobile ? 0.8 : 0.6);
  // 1 page is 100vh scroll distance. So 3 pages would be a height of 300vh.
  // Sections is the number of sections this distance is divided up into.
  // Subtract 1 as pages and sections include 0 index.
  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1));

  // https://www.desmos.com/calculator/6svqaqt7h1
  const calculateSize = (scrollTop: number) => {
    const pixelIn = appearAtSection * sectionHeight;
    const pixelOut = disappearAtSection * sectionHeight;
    // The number of pixels taken to take from min -> max and max -> min;
    // Defaulting to half a section;
    const gradientScaler = sectionHeight / 2;
    const inFunction = Math.min(
      Math.max((scrollTop - pixelIn) * (maxSize / gradientScaler), 0),
      maxSize
    );
    const outFunction = Math.min(
      Math.max((-scrollTop + pixelOut) * (maxSize / gradientScaler), 0),
      maxSize
    );
    return Math.min(inFunction, outFunction);
  };

  return {
    viewport,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    isMobile,
    margin,
    contentMaxWidth,
    sectionHeight,
    calculateSize,
  };
};

export const BackgroundItems: React.FC = () => {
  const { nodes: sectionsNodes } = useGLTF(
    "/sections.glb"
  ) as unknown as GLTFSectionsResult;
  const { nodes: geosNodes } = useGLTF(
    "/geos.glb"
  ) as unknown as GLTFGeosResult;
  const { nodes: palestineNodes } = useGLTF(
    "/freepalestine.glb"
  ) as unknown as GLTFPalestineResult;

  const { calculateSize: calculatePlane2Size } = useBackgroundItem(0, 6, 1000);
  const { calculateSize: calculateRyskSize } = useBackgroundItem(1, 3, 2200);
  const { calculateSize: calculatePropagateSize } = useBackgroundItem(
    3,
    5,
    2200
  );
  const { calculateSize: calculateDistortSize } = useBackgroundItem(2, 4, 100);
  const { calculateSize: calculatePlane3Size } = useBackgroundItem(4, 6, 200);
  // const { calculateSize: calculatePlane4Size } = useBackgroundItem(-1, 1, 200);
  const { calculateSize: calculatePalestineSize } = useBackgroundItem(
    -1,
    1,
    100
  );
  const { calculateSize: calculateGeo3Size } = useBackgroundItem(6, 9, 180);
  const { calculateSize: calculateSphereSize } = useBackgroundItem(6, 9, 300);

  const isMobile = useIsMobile();
  const { isAccesibilityMode } = useContext(ThemeContext);
  const desktopMaterial = useMemo(
    () =>
      new MeshBasicMaterial({
        wireframe: true,
        color: "black",
        wireframeLinewidth: 4,
      }),
    []
  );
  const mobileMaterial = useMemo(
    () =>
      new MeshBasicMaterial({
        wireframe: true,
        color: "#F5F2E8",
        wireframeLinewidth: 4,
      }),
    []
  );
  const geoMaterial = useMemo(
    () =>
      new MeshBasicMaterial({
        wireframe: true,
        color: "#067a67",
        wireframeLinewidth: 4,
      }),
    []
  );
  const material =
    isMobile || isAccesibilityMode ? mobileMaterial : desktopMaterial;

  const plane2Ref = useRef<Mesh | null>(null);
  const ryskRef = useRef<Mesh | null>(null);
  const propgateRef = useRef<Mesh | null>(null);
  const distortRef = useRef<Mesh | null>(null);
  const plane3Ref = useRef<Mesh | null>(null);
  const plane4Ref = useRef<Mesh | null>(null);

  const geo3Ref = useRef<Mesh | null>(null);

  const sphereRef = useRef<Mesh | null>(null);

  const palestineRef = useRef<Mesh | null>(null);

  const updateScale = (element: Mesh, calculator: (num: number) => number) => {
    const currentScale = element.scale.x;
    const targetScale = calculator(state.top.current);
    const newScale = lerp(currentScale, targetScale, 0.05);
    element.scale.set(newScale, newScale, newScale);
  };

  useFrame(() => {
    if (plane2Ref.current) {
      plane2Ref.current.rotateY(-0.001);
      plane2Ref.current.rotateZ(-0.002);
      updateScale(plane2Ref.current, calculatePlane2Size);
    }

    if (ryskRef.current) {
      ryskRef.current.rotateX(-0.0005);
      ryskRef.current.rotateZ(0.002);
      updateScale(ryskRef.current, calculateRyskSize);
    }

    if (propgateRef.current) {
      propgateRef.current.rotateX(-0.0005);
      propgateRef.current.rotateZ(0.002);
      updateScale(propgateRef.current, calculatePropagateSize);
    }

    if (distortRef.current) {
      distortRef.current.rotateX(-0.0005);
      distortRef.current.rotateZ(0.002);
      updateScale(distortRef.current, calculateDistortSize);
    }

    if (plane3Ref.current) {
      plane3Ref.current.rotateX(-0.001);
      plane3Ref.current.rotateY(-0.002);
      updateScale(plane3Ref.current, calculatePlane3Size);
    }

    // if (plane4Ref.current) {
    //   plane4Ref.current.rotateZ(-0.002);
    //   plane4Ref.current.rotateX(-0.0005);
    //   updateScale(plane4Ref.current, calculatePlane4Size);
    // }

    if (geo3Ref.current) {
      geo3Ref.current.rotateZ(-0.002);
      geo3Ref.current.rotateX(-0.0005);
      updateScale(geo3Ref.current, calculateGeo3Size);
    }

    if (sphereRef.current) {
      sphereRef.current.rotateZ(-0.002);
      sphereRef.current.rotateX(-0.001);
      updateScale(sphereRef.current, calculateSphereSize);
    }

    if (palestineRef.current) {
      palestineRef.current.rotateZ(-0.002);
      palestineRef.current.rotateX(-0.001);
      updateScale(palestineRef.current, calculatePalestineSize);
    }
  });

  return (
    <>
      {/* <mesh
        material={mobileMaterial}
        geometry={sectionsNodes.plane2.geometry}
        scale={[1, 1, 1]}
        position={[0, 0, 500]}
        rotation={[Math.PI / 4, 0, Math.PI]}
        ref={plane2Ref}
      /> */}
      <mesh
        material={material}
        geometry={sectionsNodes.rysk.geometry}
        scale={[1, 1, 1]}
        position={[isMobile ? 0 : -420, 0, -250]}
        rotation={[Math.PI / 2, 0, 0]}
        ref={ryskRef}
      />
      <mesh
        material={material}
        geometry={sectionsNodes.propagate.geometry}
        scale={[1, 1, 1]}
        position={[isMobile ? 0 : -420, 0, -250]}
        rotation={[Math.PI / 2, 0, 0]}
        ref={propgateRef}
      />
      <mesh
        material={material}
        geometry={sectionsNodes.distort.geometry}
        scale={[1, 1, 1]}
        position={[isMobile ? 0 : 400, 0, -250]}
        rotation={[0.6, 0.3, 0]}
        ref={distortRef}
      />
      <mesh
        material={material}
        geometry={sectionsNodes.plane3.geometry}
        scale={[1, 1, 1]}
        position={[isMobile ? 0 : 400, 0, -250]}
        rotation={[0.6, 0.3, 0]}
        ref={plane3Ref}
      />
      <mesh
        material={material}
        geometry={palestineNodes.Text.geometry}
        scale={[0.2, 0.2, 0.2]}
        position={[isMobile ? 0 : 0, 0, 100]}
        rotation={[Math.PI / 2, 0, 0]}
        ref={palestineRef}
      />
      <mesh
        material={material}
        geometry={geosNodes.geo3.geometry}
        scale={[0, 0, 0]}
        position={[0, 0, -250]}
        rotation={[Math.PI / 2 + 0.2, 0, 0]}
        ref={geo3Ref}
      />
    </>
  );
};
